
<template>
  <div class="product-questions">

    <div class="row">
      <div class="col-12">
        <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
            <h5 class="my-0">
            <i class="mdi mdi-comment-question-outline mr-1"></i> Frequently Asked Questions
            </h5>
            <b-button variant="primary" class="px-1 py-0" @click.prevent="popupModalShow = true" type="button">
                <i class="mdi font-14 mdi-plus"></i></b-button>
        </div>
        <div role="tablist" class="mb-3">
            <b-card no-body class="mb-1 shadow-none" v-for="(question, index) in questions" :key="index">
                <b-card-header header-tag="header" class="bg-transparent" role="tab">
                    <div class="d-sm-flex">
                        <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                            <a v-b-toggle="`questionAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                                <i class="mdi mdi-help-circle mr-1 text-primary"></i>{{question.question}}
                            </a>
                        </h5>
                        <span class="float-right">
                            <b-dropdown variant="light" size="sm" right>
                                <template slot="button-content">
                                    Action
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button @click="initEdit(question)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                                <b-dropdown-item-button @click="deleteQuestion(question.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                            </b-dropdown>
                        </span>
                    </div>
                </b-card-header>
                <b-collapse :id="`questionAccordion-${index}`" accordion="accordion" role="tabpanel">
                    <b-card-body>
                        <b-card-text>{{question.answer}}</b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered :title="editMode? 'Update Question':'Add Question'" title-class="font-18" @hide="initializeAll()">

      <div class="">
        <div class="form-group mb-3">
          <label for="question">
              Question
              <span class="text-danger">*</span>
          </label>
          <input type="text" v-validate="{required: true}" v-model="newQuestion.question" id="question" name="question" class="form-control" :class="{ 'is-invalid': errors.has('question') }" placeholder="Enter question" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('question')">{{ errors.first('question') }}</span>
        </div>
        <div class="form-group mb-3">
           <label class="">Answer</label>
            <textarea v-validate="'max:1000'" v-model="newQuestion.answer" class="form-control" rows="3" placeholder="Write Something" name="answer"></textarea>
            <span class="text-danger invalid-feedback"  v-show="errors.has('answer')">{{ errors.first('answer') }}</span>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? updateQuestion() : createQuestion()" :disabled="!isFormValid" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {

  data() {
    return {
      popupModalShow: false,
      newQuestion: {
        question: "",
        answer: ""
      },
      editMode: false,
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    },
    questions(){
      return this.$store.state.productList.product.questions
    }
  },
  methods: {
    createQuestion(){
      let formData = new FormData();
      formData.append('data', JSON.stringify(this.newQuestion));
      this.resquestProcessor(formData,`${this.$route.params.productId}/create`)
    },
    updateQuestion(){
      let formData = new FormData()
      formData.append('data', JSON.stringify(this.newQuestion))
      this.resquestProcessor(formData,`${this.newQuestion.id}/update`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/product-questions/${action}`,formData)
      .then(response => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.initializeAll()
          this.$store.commit(`productList/${ftn}_QUESTION`, response.data.question)
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    initEdit(question){
        this.editMode = true;
        this.newQuestion = {
            id: question.id,
            question: question.question,
            answer: question.answer
        }
        this.popupModalShow = true;
    },
    deleteQuestion(questionId){
      if(!questionId){return}
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this question!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#e11",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/product-questions/${questionId}/delete`)
          .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.alertSuccess(response.data.success)
            this.$store.commit("productList/DELETE_QUESTION", questionId)
          }else{
            this.alertError(response.data.error)
          }
          }).catch((error) => { console.log(error);})
        }
      });
    },
    initializeAll(){
      this.newQuestion  = { question: "", answer: "" }
      this.editMode = false;
      this.popupModalShow = false;
      this.$validator.reset()
    },
  },
}
</script>

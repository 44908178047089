<template>
  <div class="product-overview">
    <div class="row">
        <div class="col-lg-8">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group mb-3">
                    <label for="product-name">
                        Product Name
                        <span class="text-danger">*</span>
                    </label>
                    <input type="text" v-validate="{ required: true}" v-model="data.name" id="product-name" name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="e.g : 3D Logo Design" />
                    <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-3">
                    <label for="product-price">
                        Price (dollar)
                        <span class="text-danger">*</span>
                    </label>
                    <input type="number" v-validate="{ required: true, regex: /^[0-9]+$/}" v-model="data.price" class="form-control" name="price" id="product-price" placeholder="Enter Price" />
                    <span class="text-danger invalid-feedback" v-show="errors.has('price')">{{ errors.first('price') }}</span>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group text-right mt-3">
                    <b-button variant="primary" @click.prevent="updateProduct" :disabled="!isFormValid" type="button">Update</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    },
    product(){
        return this.$store.state.productList.product
    },
  },
  methods: {
    updateProduct() {
      this.$validator.validateAll().then(result => {
        if (result && this.$route.params.productId > 0) {
          this.$store.dispatch("changeLoaderValue", true)
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$http.post(`/products/${this.product.id}/update`, formData)
          .then((response)=>{
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.alertSuccess(response.data.success)
            this.$store.commit("productList/SET_PRODUCT",response.data.product)
          }else if(response.data.errors){
            response.data.errors.forEach((error) => { this.alertError(error)})
          }else{
            this.alertError(response.data.error)
          }
         }).catch((error)=>{console.log( error) })
        }
      })
    },
    patchProduct() {
      this.data = {
        name: this.product.name,
        price: this.product.price,
      }
    },
  },
  created(){
    this.patchProduct()
  },
}
</script>

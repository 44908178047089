<template>
<div class="product-descriptions">
    <div class="form-group row">
      <label class="col-12 col-form-label" for="input-meta-keys">Meta keys</label>
      <div class="col-12">
        <input type="text" v-model="data.meta_keys" id="input-meta-keys" name="meta-keys" class="form-control" v-validate="'max:200'" :class="{ 'is-invalid': errors.has('meta-keys') }" placeholder="e.g : Logo Design, Letterhead, Poster Design" />
        <span class="text-danger invalid-feedback"  v-show="errors.has('meta-keys')">{{ errors.first('meta-keys') }}</span>
      </div>
    </div>
    <div class="form-group row mt-3">
      <label class="col-12 col-form-label" for="text-area-meta-desc">Meta Description</label>
      <div class="col-12">
        <textarea id="text-area-meta-desc" v-validate="'max:1000'" v-model="data.meta_desc" class="form-control" rows="3" placeholder="Write Meta Description" name="meta-desc"></textarea>
         <span class="text-danger invalid-feedback"  v-show="errors.has('meta-desc')">{{ errors.first('meta-desc') }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h5 class="header-title">Main Description</h5>
        <quill-editor v-model="data.description" style="min-height: 100px;" :options="editorOption" />
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-end">
      <b-button variant="primary" @click.prevent="updateProduct()" :disabled="!isFormValid" type="button">Update</b-button>
    </div>
  </div>
</template>

<script>

import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      data: {
        meta_keys: "",
        meta_desc: "",
        description: "",
      },
      editorOption: {
        theme: "snow",
        placeholder: "Enter Description",
        modules: {
          toolbar: [
            [{
                font: [],
                },
                {
                 size: [],
                },
            ],
            ["bold", "italic", "underline", "strike"],
            [{
                    color: [],
                },
                {
                    background: [],
                },
            ],
            [{
                    script: "super",
                },
                {
                    script: "sub",
                },
            ],
            [{
                header: [false, 1, 2, 3, 4, 5, 6],
                },
                "blockquote",
                "code-block",
            ],
            [{
                    list: "ordered",
                },
                {
                    list: "bullet",
                },
                {
                    indent: "-1",
                },
                {
                    indent: "+1",
                },
            ],
            [
                "direction",
                {
                  align: [],
                },
            ],
            ["link"],
            ["clean"],
          ],
        },
      },
    }
  },
  computed: {
    product(){
        return this.$store.state.productList.product
    },
    isFormValid() {
      return !this.errors.any()
    },
  },
  methods: {
    updateProduct() {
       this.$validator.validateAll().then(result => {
        if (result) {
          this.$store.dispatch("changeLoaderValue", true)
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$http.post(`/products/${this.product.id}/update-desc`,formData)
          .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.alertSuccess(response.data.success)
            this.$store.commit("productList/UPDATE_DESCRIPTION",response.data.description)
          }else if(response.data.errors){
            response.data.errors.forEach((error) => { this.alertError(error)})
          }else{
            this.alertError(response.data.error)
          }
         }).catch((error)=>{console.log( error) })
        }
      })
    },
    patchProduct() {
      if(this.product.description){
        this.data = {
          meta_keys: this.product.description.meta_keys,
          meta_desc: this.product.description.meta_desc,
          description: this.product.description.description,
        }
      }
    },
  },
  created(){
    this.patchProduct()
  },
}
</script>

<template>
<div>
    <vue-dropzone id="dropzone01" ref="uploadProductVideoDrop" :use-custom-slot="true" :options="dropzoneOptions">
        <div class="dz-message needsclick m-0">
          <i class="h1 text-muted ri-upload-cloud-2-line"></i>
          <h4>Drop files here or click to upload.</h4>
          <span class="text-muted font-13">
            (Should be only a Video)
          </span>
        </div>
    </vue-dropzone>
    <div class="d-flex mt-3 justify-content-end">
        <b-button variant="primary" @click.prevent="uploadVideo" type="button">Upload</b-button>
    </div>
</div>
</template>

<script>
export default {
    data() {
    return {
      dropzoneOptions: {
        url: "http//null",
        thumbnailWidth: 550,
        maxFilesize: 500,
        maxFiles:1,
        autoProcessQueue:false,
        acceptedFiles: "video/*,.mp4,.bmp,.m4v,.avi,.mpg,.mp4",
        headers: {
          "My-Awesome-Header": "header value",
        },
        previewTemplate: this.dropzoneTemplate(),
      },
    }
  },
  methods: {
    uploadVideo(){
        if(!this.collectFiles().length){
            this.alertError("A video file is required"); return;
        }
        let formData = new FormData();
        formData.append('video', this.collectFiles()[0])
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/portfolios/${this.$route.params.productId}/add-video`,formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.alertSuccess(response.data.success)
                this.$store.commit("productList/SET_PORTFOLIOS", response.data.portfolios)
                this.clearAll()
            }else if(response.data.errors){
                response.data.errors.forEach(error => this.alertError(error))
            }else{
                this.alertError(response.data.error)
            }
       }).catch((error)=>{ console.log( error) })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.uploadProductVideoDrop){
        arrafile = [...this.$refs.uploadProductVideoDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    clearAll(){
      if(this.$refs.uploadProductVideoDrop){this.$refs.uploadProductVideoDrop.removeAllFiles();}
      this.$emit('clearAll');
    },
  },
}
</script>


<template>
  <div class="product-addons">

    <div class="row">
      <div class="col-12">
        <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
            <h5 class="my-0">
            <i class="mdi mdi-playlist-edit mr-1"></i> Product Addons
            </h5>
            <b-button variant="primary" class="px-1 py-0" @click.prevent="popupModalShow = true" type="button">
                <i class="mdi font-14 mdi-plus"></i></b-button>
        </div>
        <div role="tablist" class="mb-3">
            <b-card no-body class="mb-1 shadow-none" v-for="(addon, index) in addons" :key="index">
                <b-card-header header-tag="header" class="bg-transparent" role="tab">
                    <div class="d-sm-flex">
                        <h5 class="m-0 flex-grow-1 d-flex align-items-center">
                            <a v-b-toggle="`addonAccordion-${index}`" class="text-dark" href="javascript: void(0);">
                                <i class="mdi mdi-check mr-1 text-primary"></i>{{addon.name}} - ${{addon.charge}}
                            </a>
                        </h5>
                        <span class="float-right">
                            <b-dropdown variant="light" size="sm" right>
                                <template slot="button-content">
                                    Action
                                    <i class="mdi mdi-chevron-down"></i>
                                </template>
                                <b-dropdown-item-button @click="initEdit(addon)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                                <b-dropdown-item-button @click="deleteItem(addon.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
                            </b-dropdown>
                        </span>
                    </div>
                </b-card-header>
                <b-collapse :id="`addonAccordion-${index}`" accordion="accordion" role="tabpanel">
                    <b-card-body>
                        <h6>Charge: ${{addon.charge}}</h6>
                        <b-card-text>{{addon.description}}</b-card-text>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered :title="editMode? 'Update Addon':'Add Addon'" title-class="font-18" @hide="initializeAll()">

      <div class="">
        <div class="form-group mb-3">
          <label for="addonName">
              Name
              <span class="text-danger">*</span>
          </label>
          <input type="text" v-validate="{required: true}"
          v-model="newAddon.name" id="addonName" name="name"
          class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="Enter name" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
         <div class="form-group mb-3">
          <label for="addonCharge">
              Charge
              <span class="text-danger">*</span>
          </label>
          <input type="number" v-validate="{required: true}"
          v-model="newAddon.charge" id="addonCharge" name="charge"
          class="form-control" :class="{ 'is-invalid': errors.has('charge') }" placeholder="Enter charge" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('charge')">{{ errors.first('charge') }}</span>
        </div>
        <div class="form-group mt-3">
            <label class="" for="addonDesc">Description</label>
            <div class="">
                <textarea id="addonDesc" v-validate="'max:100'"
                v-model="newAddon.description" class="form-control"
                rows="2" placeholder="Write Description" name="description"></textarea>
                <span class="text-danger invalid-feedback"  v-show="errors.has('description')">{{ errors.first('description') }}</span>
            </div>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? updateItem() : createItem()" :disabled="!isFormValid" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {

  data() {
    return {
      popupModalShow: false,
      newAddon: {
        name: "",
        charge: "",
        description: "",
      },
      editMode: false,
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    },
    addons(){
      return this.$store.state.productList.product.addons
    }
  },
  methods: {
    createItem(){
      let formData = new FormData();
      formData.append('data', JSON.stringify(this.newAddon));
      this.resquestProcessor(formData,`${this.$route.params.productId}/create`)
    },
    updateItem(){
      let formData = new FormData()
      formData.append('data', JSON.stringify(this.newAddon))
      this.resquestProcessor(formData,`${this.newAddon.id}/update`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/product-addons/${action}`,formData)
      .then(response => {
        this.$store.dispatch("changeLoaderValue", false)
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.initializeAll()
          this.$store.commit(`productList/${ftn}_ADDON`, response.data.addon)
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    initEdit(addon){
        this.editMode = true;
        this.newAddon = {
            id: addon.id,
            name: addon.name,
            charge: addon.charge,
            description: addon.description,
        }
        this.popupModalShow = true;
    },
    deleteItem(id){
      if(!id){return}
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete a addon!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#e11",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/product-addons/${id}/delete`)
          .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.alertSuccess(response.data.success)
            this.$store.commit("productList/DELETE_ADDON", id)
          }else{
            this.alertError(response.data.error)
          }
          }).catch((error) => { console.log(error);})
        }
      });
    },
    initializeAll(){
      this.newAddon  = {
            name: "",
            charge: "",
            description: "",
       }
      this.editMode = false;
      this.popupModalShow = false;
      this.$validator.reset()
    },
  },
}
</script>


<template>
  <div class="product-attributes">

    <div class="row">
      <div class="col-12">
         <div class="mb-3 bg-light d-flex align-items-center justify-content-between p-2">
            <h5 class="my-0">
            <i class="mdi mdi-playlist-edit mr-1"></i> Product Attributes
            </h5>
            <b-button variant="primary" class="px-1 py-0" @click.prevent="popupModalShow = true" type="button">
                <i class="mdi font-14 mdi-plus"></i></b-button>
        </div>
        <ul class="list-group">
          <li class="list-group-item d-flex align-items-center justify-content-between" v-for="(attribute, index) in attributes" :key="index" >
            <span class="font-weight-bolder"><i class="mdi mdi-bookmark-check-outline text-primary mr-1"></i>{{attribute.name}} -  {{attribute.type}}</span>
            <span>
              <b-dropdown variant="light" right size="sm">
                <template slot="button-content">
                    Action
                    <i class="mdi mdi-chevron-down"></i>
                </template>
                <b-dropdown-item-button @click="initEdit(attribute)"><i class="mdi mdi-square-edit-outline"></i> Edit</b-dropdown-item-button>
                <b-dropdown-item-button @click="deleteAttribute(attribute.id)"><i class="mdi mdi-trash-can-outline"></i> Delete</b-dropdown-item-button>
              </b-dropdown>
            </span>
          </li>
        </ul>
      </div>
    </div>

    <b-modal v-model="popupModalShow" hide-footer centered :title="editMode? 'Update Attribute':'Add Attribute'" title-class="font-18" @hide="resetData()">

      <div class="">
        <div class="form-group mb-3">
          <label for="attribute-name">
              Attribute Name
              <span class="text-danger">*</span>
          </label>
          <input type="text" v-validate="{required: true}" v-model="data.name" id="attribute-name" name="name" class="form-control" :class="{ 'is-invalid': errors.has('name') }" placeholder="Enter Name" />
          <span class="text-danger invalid-feedback"  v-show="errors.has('name')">{{ errors.first('name') }}</span>
        </div>
        <div class="form-group mb-3">
          <label class="">Attribute Type</label>
          <select class="custom-select" v-validate="{required: true}" v-model="data.type" name="type" placeholder="--select type--">
            <option disabled value="">--select type--</option>
            <option value="check">check</option>
            <option value="input">input</option>
          </select>
          <span class="text-danger invalid-feedback"  v-show="errors.has('type')">{{ errors.first('type') }}</span>
        </div>
        <div class="d-flex mt-3 justify-content-end">
          <b-button variant="primary" @click.prevent="editMode? updateAttribute() : addNewAttribute()" :disabled="!isFormValid" type="button">{{editMode? 'Update':'Submit'}}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      popupModalShow: false,
      data: {
        name: "",
        type: ""
      },
      editMode: false,
      awaitingEdit:null,
    }
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
    },
    attributes(){
      return this.$store.state.productList.product.attributes
    }
  },
  methods: {
    addNewAttribute() {
      this.$validator.validateAll().then(result => {
        if (result && this.$route.params.productId > 0) {
          this.$store.dispatch("changeLoaderValue", true)
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
          this.$http.post(`/attributes/${this.$route.params.productId}/create`,formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.alertSuccess(response.data.success)
                this.$store.commit("productList/ADD_ATTRIBUTE",response.data.attribute)
                this.resetData();
            }else if(response.data.errors){
                response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
                this.alertError(response.data.error)
            }
         }).catch((error)=>{console.log( error) })
        }
      })
    },
    updateAttribute(){
      this.$validator.validateAll().then(result => {
        if (result && this.awaitingEdit) {
          this.$store.dispatch("changeLoaderValue", true)
          const formData = new FormData()
          formData.append('data', JSON.stringify(this.data))
           this.$http.post(`/attributes/${this.awaitingEdit}/update`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.alertSuccess(response.data.success)
                    this.$store.commit("productList/UPDATE_ATTRIBUTE",response.data.attribute)
                    this.resetData()
                }else if(response.data.errors){
                    response.data.errors.forEach((error) => { this.alertError(error)})
                }else{
                    this.alertError(response.data.error)
                }
          }).catch((error) => { console.log(error); this.resetData() })
        }
      })
    },
    initEdit(attribute){
        this.editMode = true;
        this.awaitingEdit = attribute.id;
        this.data = {
          name: attribute.name,
          type: attribute.type
        }
        this.popupModalShow = true;
    },
    deleteAttribute(attrId){
      if(!attrId){return}
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this attribute!",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#e11",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete it!",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/attributes/${attrId}/delete`)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.alertSuccess(response.data.success)
                this.$store.commit("productList/DELETE_ATTRIBUTE",attrId)
            }else{
                this.alertError(response.data.error)
            }
          }).catch((error) => { console.log(error);})
        }
      });
    },
    resetData(){
      this.data  = { name: "", type: "" }
      this.awaitingEdit = null;
      this.editMode = false;
      this.popupModalShow = false;
      this.$validator.reset()
    },
  },
}
</script>

<template>
    <div>
        <product-attributes />
        <hr>
        <product-addons />
         <hr>
        <frequently-questions />
    </div>
</template>
<script>
import ProductAddons from "./components/ProductAddons.vue"
import FrequentlyQuestions from "./components/FrequentlyQuestions.vue"
import ProductAttributes from "./components/ProductAttributes.vue"

export default {
    components:{
        ProductAddons,
        ProductAttributes,
        FrequentlyQuestions,
    }
}
</script>

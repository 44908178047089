<template>
  <div id="product-show-page">
    <div>
    </div>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
                <h4 class="header-title mb-2"> {{product.name}}</h4>
                <b-tabs nav-class="nav-tabs nav-bordered">
                  <b-tab title="Overview">
                   <product-overview active/>
                  </b-tab>
                  <b-tab title="Description">
                    <product-description />
                  </b-tab>
                  <b-tab title="Packages">
                     <product-packages />
                  </b-tab>
                  <b-tab title="Files">
                    <product-files />
                  </b-tab>
                  <b-tab title="Settings">
                    <product-settings />
                  </b-tab>
                </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
      <!-- <vs-alert color="danger" title="Product Not Found" :active.sync="product_not_found">
        <span>Product record with id: {{ $route.params.productId }} not found. </span>
        <span>
        <span>Check </span><span @click="$router.push('/products').catch(() => {})" class="text-inherit underline">All Products</span>
      </span>
    </vs-alert> -->
</template>

<script>
import ProductOverview from "./ProductOverview.vue"
import ProductDescription from "./ProductDescription.vue"
import ProductPackages from "./ProductPackages.vue"
import ProductFiles from "./ProductFiles.vue"
import ProductSettings from "./ProductSettings.vue"

export default {
  components: {
    ProductOverview,
    ProductDescription,
    ProductPackages,
    ProductSettings,
    ProductFiles,
  },
  data() {
    return {
      isLoading:true,
      product_not_found: true,
    }
  },
  computed: {
    product(){
      return this.$store.state.productList.product
    }
  },
  created(){
    this.$store.dispatch("productList/fetchProduct",this.$route.params.productId)
    .then((response) => {
      if(response.data.success){
        this.isLoading = false
      }else{
        this.product_not_found = true
        this.alertError(response.data.error)
      }
    }).catch((error) => { console.log(error) })
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
</style>
